<template>
	<div :class="[navshow ?'headerbody headerbodys':'headerbody']">
		<div class="header">
			<div class="logo">
				<img src="../assets/img/index_2_33.png" alt="">
				<div>西安伟童教育</div>
			</div>
			<ul class="nav">
				<li v-for="(item,index) in navtext" :class="[item.childer&&item.childer.length>0 ? 'lichilder':'']">
					<router-link :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}</router-link>
					<div class="childer" v-if="item.childer&&item.childer.length>0">
						<span v-for="(e,m) in item.childer">
							<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}</router-link>
						</span>
					</div>
				</li>
			</ul>
			<div class="img">
				<img src="../assets/img/phone2.png">
				<span>400-6565-300</span>
			</div>
		</div>
		<div class="header_phone">
			<div class="logo"><img src="../assets/img/index_2_33.png" alt=""></div>
			<div class="name">西安伟童教育科技</div>
			<div @click="is_show ? is_show = false : is_show = true" :class="[!is_show ?'lent':'lent lents']">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<el-collapse accordion :class="[is_show ?'navss nav':'nav']">
				<template v-for="(item,index) in navtext">
					<el-collapse-item v-if="item.childer&&item.childer.length>0">
						<template slot="title">
							<router-link class="navto" :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}
							</router-link>
						</template>
						<div class="childer" v-if="item.childer&&item.childer.length>0">
							<span v-for="(e,m) in item.childer">
								<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}</router-link>
							</span>
						</div>
					</el-collapse-item>
					<div v-else class="lwnav">
						<router-link class="navto" :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}
						</router-link>
					</div>
				</template>
			</el-collapse>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				is_show: false,
				navshow: false,
				navtext: [{
					name: '首页',
					address: 'Index',
					type: '1-1',
				}, {
					name: '关于伟童',
					address: 'about',
					type: '2-1',
				}, {
					name: '校企合作',
					address: 'case_index',
					type: '3-1',
					childer: [{
						name: '现代学徒制',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '岗前培训',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '职业证书',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '师资培养',
						address: 'case_index',
						type: '3-4'
					}, {
						name: '教材研发',
						address: 'case_index',
						type: '3-5'
					}]
				}, {
					name: '早幼托行业服务',
					address: 'case_index',
					type: '4-1',
					childer: [{
						name: '师资培养',
						address: 'case_index',
						type: '4-1'
					}, {
						name: '早教/托育课程输出',
						address: 'case_index',
						type: '4-2'
					}, {
						name: '托育/感统品牌加盟',
						address: 'case_index',
						type: '4-3'
					}, {
						name: '行业调查与研究',
						address: 'case_index',
						type: '4-4'
					}]
				}, {
					name: '合作洽谈',
					address: 'cooperation'
				}]
			}
		},
		watch: {
			'$route.query.type'(val) {
				// console.log(val)
				this.is_show = false
			}
		},
		created() {},
		mounted() {
			let that = this
			window.addEventListener('scroll', that.handleScrollx, true)
		},
		methods: {
			handleScrollx() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				// console.log(top)
				if (top > 50) {
					this.navshow = true
				} else {
					this.navshow = false
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.headerbody {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 99;
		transition: .4s all;

		.header_phone {
			display: none;
		}

		.header {
			width: 1200px;
			margin: 0 auto;

			>* {
				display: inline-block;
				vertical-align: middle;
			}

			.logo {
				text-align: center;
				padding: 15px 0;
				width: 8%;

				>img {
					width: 60%;
				}

				>div {
					color: #fff;
					font-size: 14px;
				}
			}

			.nav {
				width: 77%;
				text-align: center;

				>li {
					padding: 0 25px;
					padding-top: 18px;
					position: relative;
					display: inline-block;

					>a {
						color: #fff;
						display: inline-block;
						padding-bottom: 18px;
						font-size: 16px;
						transition: .4s all;
						position: relative;
						z-index: 5;
						border-bottom: 1px solid transparent;
					}

					>a:hover {}

					.childer {
						display: none;
						position: absolute;
						left: 0;
						top: 58px;
						background-color: #f8f8f8;
						border-radius: 0 0 10px 10px;
						padding: 0 25px 25px 25px;

						>span {
							display: inline-block;
							padding: 15px 0;
							width: 100%;
							border-bottom: 1px solid #e3e3e3;

							>a {
								color: #777
							}
						}

						>span:hover {
							border-color: #bdd4e2;

							>a {
								color: #333
							}
						}
					}


				}

				>li:hover {
					>a {
						color: #2f8594;
						border-color: #bdd4e2;
					}

					.childer {
						display: block;
					}
				}

				>li.lichilder {}

				>li.lichilder:hover {
					background-color: #f8f8f8;
					border-radius: 10px 10px 0 0;
				}

				>li:last-child {}
			}

			.img {
				width: 15%;

				>* {
					display: inline-block;
					vertical-align: middle;
				}

				>span {
					font-size: 14px;
					color: #666;
					margin: 0 0 0 20px;
					color: #fff;
				}

				>img {}
			}
		}
	}

	.headerbodys {
		background-color: #fff;

		.header {
			.logo {
				>div {
					color: #333;
				}
			}

			.nav {
				>li {
					>a {
						color: #666;
					}
				}
			}

			.img {
				>span {
					color: #333;
				}

			}
		}
	}

	@media screen and (max-width:900px) {
		.headerbody {
			border: none;
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 999;

			.header,
				{
				display: none;
			}

			.header_phone {
				position: relative;
				display: block;
				background-color: #fff;

				>* {
					display: inline-block;
					vertical-align: middle;
				}

				.name {
					font-size: 14px;
					color: #333;
					// margin: 10px 0 0 0;
				}

				.logo {
					width: 56px;
					margin-right: 10px;

					>img {
						width: 100%;
					}
				}

				.lent {
					margin: 20px 20px 0 0;
					width: 25px;
					float: right;

					>span {
						display: block;
						width: 100%;
						height: 2px;
						margin-bottom: 8px;
						background-color: #fdd100;
						transition: all .5s;
						opacity: 1;
					}

					>span:last-child {
						margin: 0;
					}
				}

				.lents {
					>:nth-child(1) {
						transform: translateY(10px) rotate(45deg);
						transform-origin: center center
					}

					>:nth-child(3) {
						display: none;
						opacity: 0;
						width: 0;
						background-color: #fff;
					}

					>:nth-child(2) {
						transform: translateY(0px) rotate(-45deg);
						transform-origin: center center
					}
				}

				.nav {
					position: absolute;
					top: 60px;
					left: 0;
					background-color: #fff;
					margin-left: -100vw;
					opacity: 0;
					width: 100%;
					padding: 0 10px;
					box-sizing: border-box;
					transition: all .4s;

					.navto {
						color: #333;
						font-size: 16px;
					}

					.childer {
						>span {
							display: block;
							padding: 10px 0 10px 10px;

							border-bottom: 1px solid #EBEEF5;

							>a {
								font-size: 14px;
								color: #999;
								width: 100%;
								display: inline-block;
							}
						}
					}

					.lwnav {
						// padding: 15px 0;
						border-bottom: 1px solid #EBEEF5;
						height: 48px;

						>a {
							width: 100%;
							display: inline-block;
							font-size: 16px;
							line-height: 48px;
						}
					}

					>:last-child {
						border-bottom: none;
					}
				}

				.navss {
					margin-left: 0;
					opacity: 1;
				}
			}
		}
	}
</style>
