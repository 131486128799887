import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../view/index.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		component: Index
	},{
		path: '/about',
		name: 'about',
		component: () => import('../view/about.vue')
	},{
		path: '/cooperation',
		name: 'cooperation',
		component: () => import('../view/cooperation.vue')
	},{
		path: '/case_index',
		name: 'case_index',
		component: () => import('../view/new_file.vue')
	},
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	next()
})

export default router
