<template>
	<div>
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<div class="about">
			<img class="pc" src="../assets/img/index1-1.png" alt="">
			<img class="ph" src="../assets/img/phindex1-1.jpg" alt="">
			<div class="conts">
				<div class="titles"><span>我们的坏境</span></div>
				你是一只小鸟，在伟童的天空中自在飞翔<br>
				你是一只小鹿，在伟童爱的森林里欢愉跑跳<br><br>
				3500余平米的占地面积 <br>
				充满了台湾创意元素的空中操场<br>
				逃离城市的束缚,让眼睛充满探求远方的欲望 <br>
				伟童，每一处都用心<br>
				每一景都用情
			</div>
		</div>
		<div class="example">
			<div class="titles"><span>在伟童每一次呼吸都是生命的味道</span></div>
			<div class="list">
				<span class="left">
					<img src="../assets/img/index2-1.png" alt="">
				</span>
				<p>每一间教室都配备独立宽敞的卫生间，教寝分离，给您
					的孩子最舒适的生活环境。每班严格把控幼儿数量，最贴心
					的服务给最独一无二的童年。</p>
			</div>
			<div class="list">
				<p>每月1个主题,带领您的孩子3年走遍30多个国家,领略世界各地风情，并将主题国家的学习渗透到教学活动、园所饮食、环境创设中，在每一个小确幸的日子里耳濡目染国际范，开拓视野。</p>
				<span class="right">
					<img src="../assets/img/index2-2.png" alt="">
				</span>
			</div>
			<div class="list">
				<span class="left">
					<img src="../assets/img/index2-3.png" alt="">
				</span>
				<p>我园构建以五大领域为基础，以生命教育课程、通识课程、品格教育、EB全脑开发课、科技学院课程为框架的教学体系，并引进美式感统课程和台湾尼塔艺术课程滋养幼儿体艺潜能。</p>
			</div>
			<div class="list">
				<p> 外教入园执导专业吉的堡英语教学方法。丰富的社会实践活动，让幼儿与社会触碰，成为独立的自己。在伟童品诺，小人国的世界里有真实、真诚而丰富的生活色彩，还有深入灵魂的精神滋养。</p>
				<span class="right">
					<img src="../assets/img/index2-4.png" alt="">
				</span>
			</div>
		</div>
		<div class="idea">
			<div class="titles"><span>伟童致力培育幸福儿童</span></div>
			<span class="titimg"><img src="../assets/img/index3-1.png"></span>
			<p class="cont">
				每个班级独享一部品牌直饮水设备，让你喝到的每一口水都无污染、无危害，在安全中品味大自然的甘露。
			</p>
			<p class="cont">每一片菜叶都经过实验室农药残留检测，每一粒米一滴油都来自管控严格的一站式配送平台国家认证高级营养师让每一天营养达标，合理膳食。</p>
			<p class="cont">
				每一位有幸被聘为伟童的教师，她们都必须是因为爱孩子而希望成为幼师，我们看中技能，但不以技能为录用前提，教师第一素养“持久地爱每一个与你没关系却又关乎幸福的孩子”。全体教师均为学前教育相关专业毕业，持证上岗，定期考核，这是一支充满了活力与爱，耐心与坚持的温暖团队，一路陪伴孩子进入小学。
			</p>
		</div>
		<div class="aboutuser">
			<div class="titles"><span>关于我们</span></div>
			<p class="abp1">伟童幼儿园是一所引进台湾生命教育理念的高端连锁幼儿园。我们每一所园都将为350—500名</p>
			<p class="abp2">幼儿提供高品质的国际化学前教育服务，让每一个孩子都享受真正内心自由并且精神富足的童年。</p>
			<p class="abp3">每个园所都拟定规划13个教学班， 并为孩子们构建了绘本故事屋、亲子烘焙坊、尼塔艺术手作室、</p>
			<p class="abp4">科技体验馆等高端部室。 童年只有一次，交付伟童，不负初心、不负光阴。</p>
			<p class="abp5">伟童幼儿园传播台湾生命教育理念，引导幼儿习得感恩之心，善于发现和珍惜生活中的美好</p>
			<p class="abp6">事物，学会处理与自己、与他人、与社会、与自然之间的关系，成为高情商的幸福人。</p>
			<img class="abm1" src="../assets/img/index4-1.png" alt="">
			<img class="abm2" src="../assets/img/index4-2.png" alt="">
			<img class="abm3" src="../assets/img/index4-3.png" alt="">
		</div>
		<div class="kindergarten">
			<div class="titles"><span>线下幼儿园</span></div>
			<div class="div">
				<p class="tits">临潼区伟童幼儿园</p>
				<p class="cont">
					<img src="../assets/img/index5-1.png" alt=""><span>陕西省西安市临潼区行者路小寨桥立鑫万象城</span>
				</p>
			</div>
			<div class="div">
				<p class="tits">未央区伟童幼儿园</p>
				<p class="cont">
					<img src="../assets/img/index5-1.png" alt=""><span>陕西省西安市未央区汉渠北路与御井路十字向东500米利君未来城小区内</span>
				</p>
			</div>
		</div>
		<!-- <div class="indeximg"><img src="../assets/img/901c055a431c391ef4262629fe312bb.jpg" alt=""></div> -->
		<!-- <div class="phoneindeximg"><img src="../assets/img/phoneindex.jpg" alt=""></div> -->
		<footers></footers>
	</div>
</template>

<script>
	import headers from '../components/header2.vue'
	import footers from '../components/footer.vue'
	export default {
		components: {
			headers,
			footers
		},
		data() {
			return {
				imglist: [
					require('../assets/img/a52001c573c6511c3d009a51189cf53.png')
				],
				imglist2: [
					require('../assets/img/90b38e98fa91855976d30ef2b935fdf.png')
				]
			}
		},
		watch: {

		},
		created() {
			this.querySortImg()
		},
		mounted() {
			
		},
		methods: {
			querySortImg(){
				let gh = []
				this.$post('querySortImg',{
					sortHead:1,
					sortOrder:0,
					sortFrom:1
				}).then((res)=>{
					if (res&&res.length>0) {
						this.imglist = []
						this.imglist2 = []
						res.forEach((e, m) => {
							let h = e.sortImg.split(',')
							h.forEach((v, b) => {
								if (e.sortSign == 0) {
									this.imglist.push(v)
								} else {
									gh.push(v)
								}
							})
						})
						if (!gh || gh.length <= 0) {
							this.imglist.forEach((f, g) => {
								f = f + '?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						}else{
							this.imglist2 = gh
						}
					} else {
					
					}
				})
				this.$nextTick(()=>{
					
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.indeximg,
	.phoneindeximg {
		font-size: 0;

		>img {
			width: 100%;
		}
	}


	.phonebannerimgs,
	.phoneindeximg {
		display: none;
	}

	.titles {
		text-align: center;
		margin: 0 0 20px 0;
		display: inline-block;
		width: 100%;
		overflow: hidden;

		>span {
			font-family: 楷体;
			letter-spacing: 10px;
			font-size: 24px;
			padding: 0 30px 20px 30px;
			display: inline-block;
			border-bottom: 1px solid #4791a3;
		}
	}

	.about {
		position: relative;
		width: 1200px;
		font-size: 0;
		margin:80px auto;
		text-align: center;

		>img {
			width: 70%;
		}
		.ph{
			display: none;
		}
		.conts {
			font-size: 16px;
			line-height: 35px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.example {
		width: 1200px;
		font-size: 0;
		margin:0 auto 80px auto;
		overflow: hidden;

		.list {
			overflow: hidden;
			margin: 0 0 60px 0;

			>* {
				display: inline-block;
				vertical-align: middle;
				font-size: 0;
			}

			>span {
				width: 47%;
				border-radius: 10px;
				overflow: hidden;

				>img {
					width: 100%;
				}
			}

			>p {
				width: 50%;
				font-size: 18px;
				line-height: 40px;
				letter-spacing: 3px;
				text-indent: 30px;
				color: #666;
				padding: 20px;
				box-sizing: border-box;
			}

			.left {
				margin-right: 3%;
			}

			.rigth {
				margin-left: 3%;
			}
		}

		>:nth-child(2) {
			margin-top: 40px;
		}
	}

	.idea {
		width: 1200px;
		font-size: 0;
		margin:0 auto 80px auto;
		overflow: hidden;
		.titles{
			margin-bottom: 80px;
		}
		.titimg {
			font-size: 0;
			margin: 0 0 60px 0;
			display: inline-block;
			width: 100%;

			>img {
				width: 100%;
			}
		}

		.cont {
			font-size: 18px;
			line-height: 40px;
			text-indent: 36px;
			letter-spacing: 3px;
			color: #524e50;
		}
	}

	.aboutuser {
		width: 1200px;
		font-size: 0;
		margin:0 auto 80px auto;
		overflow: hidden;
		position: relative;
		.titles{
			margin: 0 0 80px 0;
		}
		>img {
			position: absolute;
			left: 0;
			top: 0;
		}

		>p {
			font-size: 18px;
			letter-spacing: 1px;
			margin: 0 0 25px 0;
			color: #524e50;
		}

		.abp1 {
			margin-left: 22%;
		}

		.abp2 {
			margin-left: 23%;
		}

		.abp3 {
			margin-left: 21%;
		}

		.abp4 {
			margin-left: 12%;
		}

		.abp5 {
			margin-left: 12%;
		}

		.abp6 {
			margin-left: 9%;
		}

		.abm1 {
			top: 25%;
			    left: 10%;
		}

		.abm2 {
			    top: 57%;
			    left: 0px;
		}

		.abm3 {
			left: auto;
			    right: 12%;
			    top: auto;
			    bottom: 2%;
		}

	}
	.kindergarten{
		width: 1200px;
		font-size: 0;
		margin:0 auto 80px auto;
		overflow: hidden;
		position: relative;
		.titles{
			margin-bottom: 80px;
		}
		.div{
			width: 50%;
			display: inline-block;
			vertical-align: top;
			.tits{
				font-size: 24px;
				letter-spacing: 2px;
				color: #000;
				margin: 0 0 60px 0;
				text-align: center;
			}
			.cont{
				>*{
					display: inline-block;
					vertical-align: middle;
				}
				>img{
					width: 5%;
				}
				>span{
					font-size: 18px;
					    letter-spacing: 1px;
					    color: #666;
					    width: 93%;
					    margin-left: 2%;
				}
			}
		}
	}

	@media screen and (max-width:900px) {

		.bannerimgs,
		.indeximg {
			display: none;
		}

		.phonebannerimgs,
		.phoneindeximg {
			display: block;
		}

		.phonebannerimgs {
			margin-top: 62px;
		}

		.phoneindeximg {
			padding: 0 10px;
			box-sizing: border-box;
			overflow: hidden;
		}
		.about,.example,.idea,.aboutuser,.kindergarten{
			width: 97%;
			margin: 40px auto;
		}
		.titles {
			margin: 0 0 10px 0;
			>span {
				letter-spacing: 2px;
				font-size: 18px;
				padding: 0 20px 10px 20px;
			}
		}
		.about{
			>img{
				width: 100%;
			}
			.pc{
				display: none;
			}
			.ph{
				display: inline-block;
			}
			.conts{
				font-size: 12px;
				    line-height: inherit;
				    width: 57%;
				    top: 50%;
			}
		}
		.example {
			.list {
				margin: 0 0 20px 0;
				>span {
					width: 47%;
				}
		
				>p {
					width: 51%;
					font-size: 14px;
					line-height: initial;
					letter-spacing: 1px;
					text-indent: 30px;
					padding: 10px;
				}
		
				.left {
					margin-right: 2%;
				}
		
				.rigth {
					margin-left: 2%;
				}
			}
		
			>:nth-child(2) {
				margin-top: 20px;
			}
		}
		.idea {
			.titles{
				margin-bottom: 60px;
			}
			.titimg {
				margin: 0 0 20px 0;
				>img {
					width: 100%;
				}
			}
		
			.cont {
				font-size: 14px;
				line-height:25px;
				text-indent: 30px;
				letter-spacing: 1px;
			}
		}
		.aboutuser {
			.titles{
				margin: 0 0 40px 0;
			}
			>img{
				width: 20%;
			}
			>p {
				font-size: 14px;
				letter-spacing: 1px;
				margin: 0 0 5px 0;
				color: #524e50;
			}
		
			.abp1 {
				margin-left: 27%;
			}
		
			.abp2 {
				margin-left: 24%;
			}
		
			.abp3 {
				margin-left: 20%;
			}
		
			.abp4 {
				margin-left: 6%;
				width: 80%;
			}
		
			.abp5 {
				margin-left: 2%;
				width: 70%;
			}
		
			.abp6 {
				margin-left: 2%;
				width: 80%;
			}
		
			.abm1 {
				top: 16%;
				    left: 5%;
			}
		
			.abm2 {
				    top: 35%;
				    left: 0px;
			}
		
			.abm3 {
				left: auto;
				    right: 2%;
				    top: auto;
				    bottom: 10%;
			}
		
		}
		.kindergarten{
			.titles{
				margin-bottom: 40px;
			}
			.div{
				width: 100%;
				display: block;
				.tits{
					font-size: 16px;
					letter-spacing: 1px;
					margin: 0 0 20px 0;
				}
				.cont{
					>*{
						display: inline-block;
						vertical-align: middle;
					}
					>img{
						width: 6%;
					}
					>span{
						font-size: 14px;
						    letter-spacing: 1px;
						    color: #666;
						    width: 92%;
						    margin-left: 2%;
					}
				}
			}
			.div:nth-child(2){
				margin-bottom: 20px;
			}
		}
	}
</style>
