<template>
	<div class="footers">
		<div class="plate1" v-if="pmkd">
			<div class="left">
				<div v-for="(item,index) in navtext2" class="list">
					<router-link :to="item.address">{{item.name}}</router-link>
					<ul v-if="item.childer&&item.childer.length>0">
						<li v-for="(e,m) in item.childer">
							<router-link :to="e.address">{{e.name}}</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="rigth">
				<div><img src="../assets/img/phone.png" alt=""><span>400-6565-300</span></div>
				<!-- <div><img src="../assets/img/wx.png" alt=""><span>000000</span></div> -->
			</div>
		</div>
		<div class="plate1" v-else>
			<div class="left">
				<div v-for="(item,index) in navtext" class="list">
					<router-link :to="item.address">{{item.name}}</router-link>
					<ul v-if="item.childer&&item.childer.length>0">
						<li v-for="(e,m) in item.childer">
							<router-link :to="e.address">{{e.name}}</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="rigth">
				<div><img src="../assets/img/phone.png" alt=""><span>400-6565-300</span></div>
				<!-- <div><img src="../assets/img/wx.png" alt=""><span>000000</span></div> -->
			</div>
		</div>
		<div class="plate2">
			<div>手机号：400-6565-300 邮箱：3332001250@qq.com 地址：西安市高新区绿地领海大厦B座808</div>
			<div>西安伟童教育科技有限公司 备案号：<a href="https://beian.miit.gov.cn" target="_blank">陕ICP备17009726号-7</a></div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				pmkd:false,
				navtext2: [{
					name: '首页',
					address: 'Index',
					type: '1-1',
				}, {
					name: '关于伟童',
					address: 'about',
					type: '2-1',
				}, {
					name: '校企合作',
					address: 'case_index',
					childer: [{
						name: '现代学徒制服务',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '岗前培训服务',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '职业证书服务',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '实践性师资培养',
						address: 'case_index',
						type: '3-4'
					}, {
						name: '应用型教材研发',
						address: 'case_index',
						type: '3-5'
					}]
				}, {
					name: '早幼托行业服务',
					address: 'case_index',
					childer: [{
						name: '师资培养',
						address: 'case_index',
						type: '4-1'
					}, {
						name: '早教/托育课程输出',
						address: 'case_index',
						type: '4-2'
					}, {
						name: '托育/感统品牌加盟',
						address: 'case_index',
						type: '4-3'
					}, {
						name: '行业调查与研究',
						address: 'case_index',
						type: '4-4'
					}]
				}, {
					name: '合作洽谈',
					address: 'cooperation'
				}],
				navtext: [ {
					name: '合作洽谈',
					address: 'cooperation'
				}, {
					name: '关于伟童',
					address: 'about',
					type: '2-1',
				}, {
					name: '校企合作',
					address: 'case_index',
					childer: [{
						name: '现代学徒制',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '岗前培训',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '职业证书',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '师资培养',
						address: 'case_index',
						type: '3-4'
					}, {
						name: '教材研发',
						address: 'case_index',
						type: '3-5'
					}]
				}, {
					name: '早幼托行业服务',
					address: 'case_index',
					childer: [{
						name: '师资培养',
						address: 'case_index',
						type: '4-1'
					}, {
						name: '早教/托育课程输出',
						address: 'case_index',
						type: '4-2'
					}, {
						name: '托育/感统品牌加盟',
						address: 'case_index',
						type: '4-3'
					}, {
						name: '行业调查与研究',
						address: 'case_index',
						type: '4-4'
					}]
				}]
			}
		},
		watch: {

		},
		created() {},
		mounted() {
			let l = document.body.clientWidth
			if(l>600){
				this.pmkd = true
			}else{
				this.pmkd = false
			}
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.footers {
		background-color: #333;

		.plate1 {
			padding: 40px 0;
			box-sizing: border-box;
			overflow: hidden;
			width: 1200px;
			margin: 0 auto;

			>* {
				display: inline-block;
				vertical-align: top;
				font-size: 0;
			}

			.left {
				width: 80%;

				>* {
					display: inline-block;
					vertical-align: top;
				}

				.list {
					margin-right: 10%;

					>a {
						display: inline-block;
						font-size: 18px;
						color: #fff;
						margin: 0 0 20px 0;
					}

					>ul {
						li {
							>a {
								display: inline-block;
								font-size: 14px;
								color: #fff;
								margin: 10px 0;
							}
						}
					}
				}

				>:last-child {
					margin-right: 0;
				}
			}

			.rigth {
				width: 20%;

				>div {
					display: inline-block;
					vertical-align: top;

					>img {
						display: block;
						margin: 0 auto;
					}

					>span {
						font-size: 12px;
						display: inline-block;
						margin-top: 20px;
						color: #fff;

					}
				}

				>:last-child {
					margin-left: 50%;
				}
			}
		}

		.plate2 {
			background-color: #000;
			padding: 20px 0;
			text-align: center;

			>div {
				font-size: 16px;
				color: #fff;

				a {
					font-size: 16px;
					color: #fff;
				}
			}

			>:first-child {
				margin-bottom: 15px;
			}
		}
	}

	@media screen and (max-width:900px) {
		.footers {
			.plate1 {
				padding: 20px 10px;
				width: 100%;

				.left {
					width: 100%;

					.list {
						width: 45%;
						>a{
							font-size: 16px;
							margin: 0 0 10px 0;
						}
						>ul{
							>li{
								>a{
									font-size: 12px;
									margin: 5px 0;
								}
							}
						}
					}

					>.list:nth-child(2n) {
						margin-right: 0;
					}
				}

				.rigth {
					display: none;
				}
			}
			.plate2 {
				padding: 20px 10px;
			
				>div {
					font-size: 14px;
			
					a {
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
